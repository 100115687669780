<script>
import axios from "@/axs/axios.js";
import Header from "@/components/Header.vue";
import SideBar from "@/components/SideBar.vue";
import Swal from "sweetalert2";

export default {
    components: {
        Header,
        SideBar
    },
    data() {
        return {
            selectedPaylink: null,
            step: 1,
            formData: {
                objet: "",
                montant_min: "",
                description: "",
                mode: "",
                expiration_at: "",
                withdrawal_number: "",
                image: null,
                client_id: "",
            },
            paylinks: [],
            currentPage: 1,
            totalPages: 1,
            startDate: "",
            endDate: "",
            status: "",
            mode: "",
            objet: "",
        };
    },
    watch: {
        startDate() {
            this.fetchPaylinks();
        },
        endDate() {
            this.fetchPaylinks();
        },
        status() {
            this.fetchPaylinks();
        },
        mode() {
            this.fetchPaylinks();
        },
        objet() {
            this.fetchPaylinks();
        }
    },
    mounted() {
        this.fetchPaylinks();
        const userDataString = sessionStorage.getItem("userData");
        if (userDataString) {
            try {
                const userData = JSON.parse(userDataString);
                this.formData.client_id = userData.additional_info.id;
            } catch (error) {
                console.error(
                    "Erreur lors du parsing de userData depuis sessionStorage:",
                    error
                );
            }
        }
    },
    methods: {
        fetchPaylinks(page = 1) {
            const clientId = this.getClientIdFromSession();
            if (clientId) {
                axios
                    .get(`/paylinks/${clientId}`, {
                        params: {
                            page: page,
                            startDate: this.startDate,
                            endDate: this.endDate,
                            status: this.status,
                            mode: this.mode,
                            objet: this.objet
                        },
                    })
                    .then((response) => {
                        this.paylinks = response.data.data;
                        this.currentPage = response.data.current_page;
                        this.totalPages = response.data.last_page;
                    })
                    .catch((error) => {
                        console.error(
                            "Erreur lors de la récupération des paylinks:",
                            error
                        );
                    });
            }
        },

        deactivatePaylink() {
            if (!this.selectedPaylink) {
                Swal.fire({
                    icon: "error",
                    text: "Veuillez sélectionner un paylink.",
                });
                return;
            }

            axios.post(`/paylinks/${this.selectedPaylink}/deactivate`)
                .then((response) => {
                    Swal.fire({
                        icon: "success",
                        text: "Le paylink a été désactivé avec succès.",
                        toast: true,
                        position: "top-end",
                        showConfirmButton: false,
                        timer: 3000,
                    });
                    // Recharger la liste des paylinks après la désactivation
                    this.fetchPaylinks();
                })
                .catch((error) => {
                    Swal.fire({
                        icon: "error",
                        text: "Erreur lors de la désactivation du paylink.",
                    });
                    console.error("Erreur lors de la désactivation:", error);
                });
        },

        paylinkLink() {
            if (!this.selectedPaylink) {
                Swal.fire({
                    icon: "error",
                    text: "Veuillez sélectionner un paylink.",
                });
                return;
            }
            const paymentLink = `/paymentlink/${this.selectedPaylink}`;
            window.open(paymentLink, '_blank');
        },

        goToPaymentTransactions() {
            if (!this.selectedPaylink) {
                Swal.fire({
                    icon: "error",
                    text: "Veuillez sélectionner un paylink.",
                });
                return;
            }
            const transactionsLink = `/client/paylink-transactions/${this.selectedPaylink}`;
            window.location.href = transactionsLink;
        },

        sharePaylink() {
            if (!this.selectedPaylink) {
                Swal.fire({
                    icon: "error",
                    text: "Veuillez sélectionner un paylink.",
                });
                return;
            }
            const paymentLink = `https://afrikmoney.com/paymentlink/${this.selectedPaylink}`;
            const message = `Voici mon lien de paiement, vous pouvez me soutenir ou me payer via ce lien : ${paymentLink}`;
            const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(message)}`;
            window.open(whatsappUrl, '_blank');
        },

        getClientIdFromSession() {
            const userDataString = sessionStorage.getItem("userData");
            if (userDataString) {
                try {
                    const userData = JSON.parse(userDataString);
                    return userData.additional_info.id;
                } catch (error) {
                    console.error(
                        "Erreur lors du parsing de userData depuis sessionStorage:",
                        error
                    );
                }
            }
            return null;
        },
        goToPage(page) {
            if (page > 0 && page <= this.totalPages) {
                this.fetchPaylinks(page);
            }
        },
        nextStep() {
            if (this.step === 1 && (!this.formData.objet || !this.formData.montant_min)) {
                Swal.fire({
                    icon: "error",
                    text: "Veuillez remplir tous les champs requis pour cette étape.",
                });
                return;
            }
            if (this.step === 2 && (!this.formData.expiration_at || !this.formData.withdrawal_number)) {
                Swal.fire({
                    icon: "error",
                    text: "Veuillez remplir tous les champs requis pour cette étape.",
                });
                return;
            }
            this.step++;
        },
        prevStep() {
            this.step--;
        },
        handleImageUpload(event) {
            this.formData.image = event.target.files[0];
        },
        submitForm() {
            const formData = new FormData();
            for (const key in this.formData) {
                formData.append(key, this.formData[key]);
            }
            axios
                .post("/add-paylinks", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    },
                })
                .then(() => {
                    Swal.fire({
                        icon: "success",
                        title: "Paylink créé avec succès !",
                        toast: true,
                        position: "top-end",
                        showConfirmButton: false,
                        timer: 3000,
                    }).then(() => {
                        window.location.reload();
                    });
                })
                .catch((error) => {
                    Swal.fire({
                        icon: "error",
                        title: "Erreur lors de la soumission",
                        toast: true,
                        position: "top-end",
                        showConfirmButton: false,
                        timer: 3000,
                    });
                });
        },
    },
};
</script>

<template>
<div>
    <Header />
    <div class="container-fluid">
        <div class="row">
            <SideBar />
            <main class="main-wrapper col-md-9 ms-sm-auto py-4 col-lg-10 px-md-4 border-start">
                <div class="row my-4">
                    <div class="col-lg-12 col-12">
                        <div class="custom-block bg-white">

                            <h5 class="mb-4">RECHERCHER</h5>
                            <div class="row mb-4">
                                <div class="col-md-2">
                                    <label for="startDate">Date de début</label>
                                    <input type="date" id="startDate" class="form-control" v-model="startDate" />
                                </div>
                                <div class="col-md-2">
                                    <label for="endDate">Date de fin</label>
                                    <input type="date" id="endDate" class="form-control" v-model="endDate" />
                                </div>
                                <div class="col-md-2">
                                    <label for="statut">Statut</label>
                                    <select id="statut" class="form-control" v-model="status">
                                        <option value="">Tous</option>
                                        <option value="pending">En attente</option>
                                        <option value="approved">Succès</option>
                                        <option value="rejected">Échec</option>
                                        <option value="expired">Expiré</option>
                                    </select>
                                </div>
                                <div class="col-md-2">
                                    <label for="mode">Mode</label>
                                    <select id="mode" class="form-control" v-model="mode">
                                        <option value="">Tous</option>
                                        <option value="public">Public</option>
                                        <option value="privé">Privé</option>
                                    </select>
                                </div>
                                <div class="col-md-4">
                                    <label for="objet">Objet</label>
                                    <select id="objet" class="form-control" v-model="objet">
                                        <option value="">Tous</option>
                                        <option value="Anniverssaire">Anniverssaire</option>
                                        <option value="Mariage">Mariage</option>
                                        <option value="Achat">Achat</option>
                                        <option value="Aide ou don">Aide ou don</option>
                                        <option value="Contribution">Contribution</option>
                                        <option value="Participation">Participation</option>
                                        <option value="Recouvrement">Recouvrement</option>
                                        <option value="Autre">Autre</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row my-4">
                    <div class="col-lg-12 col-12">
                        <div class="custom-block bg-white">
                            <h5 class="mb-4">GERER MES PAYLINKS</h5>
                            <button type="button" class="btn btn-sm" data-bs-toggle="modal" data-bs-target="#paylinkModal" style="background-color: #006666; color: white;">
                                <i class="bi bi-plus-circle"></i> Ajouter un Paylink
                            </button>
                            <span>&nbsp;</span>
                            <button type="button" class="btn btn-sm" @click="paylinkLink" style="background-color: #006666; color: white;">
                                <i class="bi bi-link-45deg"></i> Lien
                            </button>
                            <span>&nbsp;</span>
                            <button type="button" class="btn btn-sm" @click="goToPaymentTransactions" style="background-color: #006666; color: white;">
                                <i class="bi bi-credit-card"></i> Paiement
                            </button>
                            <span>&nbsp;</span>
                            <button type="button" class="btn btn-sm" @click="sharePaylink" style="background-color: #006666; color: white;">
                                <i class="bi bi-share"></i> Partager
                            </button>
                            <span>&nbsp;</span>
                            <button type="button" class="btn btn-sm" data-bs-toggle="modal" data-bs-target="#payoutModal" style="background-color: #006666; color: white;">
                                <i class="bi bi-arrow-counterclockwise"></i> Demander un remboursement
                            </button>
                            <span>&nbsp;</span>
                            <button type="button" class="btn btn-sm" @click="deactivatePaylink" style="background-color: #006666; color: white;">
                                <i class="bi bi-x-circle"></i> Désactiver le Paylink
                            </button>
                        </div>
                    </div>
                </div>

                <div class="row my-4">
                    <div class="col-lg-12 col-12">
                        <div class="custom-block bg-white">
                            <h5 class="mb-4">MES PAYLINKS</h5>
                            <div class="table-responsive">
                                <table class="account-table table texte-sm">
                                    <thead style="background-color: #006666">
                                        <tr>
                                            <th scope="col" style="color: white">

                                            </th>
                                            <th scope="col" style="color: white">N°</th>
                                            <th scope="col" style="color: white" class="d-none d-md-table-cell">CREER LE</th>
                                            <th scope="col" style="color: white">EXPIRATION</th>
                                            <th scope="col" style="color: white">SOLDE</th>
                                            <th scope="col" style="color: white" class="d-none d-md-table-cell"> NUMERO</th>
                                            <th scope="col" style="color: white">MODE</th>
                                            <th scope="col" style="color: white" class="d-none d-md-table-cell">OBJET</th>
                                            <th scope="col" style="color: white">STATUT</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(paylink, index) in paylinks" :key="paylink.id" @click="selectedPaylink = paylink.id" :class="{ 'bg-warning': selectedPaylink === paylink.id }">
                                            <td :class="{ 'bg-warning': selectedPaylink === paylink.id }">
                                                <input type="radio" v-model="selectedPaylink" :value="paylink.id" name="paylinkSelection" />
                                            </td>
                                            <td :class="{ 'bg-warning': selectedPaylink === paylink.id }">{{ (currentPage - 1) * 15 + index + 1 }}</td>
                                            <td :class="{ 'bg-warning': selectedPaylink === paylink.id }">{{ new Date(paylink.created_at).toLocaleDateString() }}</td>
                                            <td :class="{ 'bg-warning': selectedPaylink === paylink.id }">{{ new Date(paylink.expiration_at).toLocaleDateString() }}</td>
                                            <td :class="{ 'bg-warning': selectedPaylink === paylink.id }">{{ paylink.solde }} FCFA</td>
                                            <td :class="{ 'bg-warning': selectedPaylink === paylink.id }">{{ paylink.withdrawal_number }}</td>
                                            <td :class="{ 'bg-warning': selectedPaylink === paylink.id }">{{ paylink.mode }}</td>
                                            <td :class="{ 'bg-warning': selectedPaylink === paylink.id }">{{ paylink.objet }}</td>
                                            <td :class="{ 'bg-warning': selectedPaylink === paylink.id }">
                                                <span v-if="paylink.status === 'pending'" class="badge rounded-pill text-bg-warning">En attente</span>
                                                <span v-if="paylink.status === 'approved'" class="badge rounded-pill text-bg-success">Approuvé</span>
                                                <span v-if="paylink.status === 'rejected'" class="badge rounded-pill text-bg-danger">Rejeté</span>
                                                <span v-if="paylink.status === 'expired'" class="badge rounded-pill text-bg-primary">Expiré</span>
                                                <span v-if="paylink.status === 'desactived'" class="badge rounded-pill text-bg-secondary">Expiré</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <nav aria-label="Page navigation">
                                <ul class="pagination">
                                    <li class="page-item" :class="{ disabled: currentPage === 1 }">
                                        <a class="page-link" @click="goToPage(currentPage - 1)" href="#" aria-label="Previous">
                                            <span aria-hidden="true">&laquo;</span>
                                        </a>
                                    </li>
                                    <li class="page-item" :class="{ active: currentPage === page }" v-for="page in totalPages" :key="page">
                                        <a class="page-link" @click="goToPage(page)" href="#">{{page}}</a>
                                    </li>
                                    <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                                        <a class="page-link" @click="goToPage(currentPage + 1)" href="#" aria-label="Next">
                                            <span aria-hidden="true">&raquo;</span>
                                        </a>
                                    </li>
                                </ul>
                            </nav>

                            <div class="modal fade" id="paylinkModal" tabindex="-1" aria-labelledby="paylinkModalLabel" aria-hidden="true">
                                <div class="modal-dialog modal-lg">
                                    <div class="modal-content">
                                        <div class="modal-header" style="background-color: rgb(0, 102, 102)">
                                            <h5 class="modal-title" id="paylinkModalLabel" style="color: #ffffff">
                                                Nouveau Paylink
                                            </h5>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>

                                        <div class="modal-body">
                                            <div class="alert alert-info" role="alert">
                                                Un "Paylink" est un lien de paiement sécurisé que vous
                                                pouvez partager avec vos clients. Lorsqu'un client
                                                clique sur ce lien, il est redirigé vers une page de
                                                paiement où il peut régler le montant spécifié. Cela
                                                facilite la collecte de paiements en ligne sans avoir
                                                à configurer une page de paiement complexe.
                                            </div>

                                            <form @submit.prevent="submitForm">
                                                <div v-if="step === 1">
                                                    <div class="form-group">
                                                        <label for="objet">
                                                            L'objet de votre PayLink (Mariage, Anniverssaire, etc..).
                                                        </label><br><br>
                                                        <select v-model="formData.objet" class="form-control" id="objet" required>
                                                            <option disabled value="">Sélectionnez</option>
                                                            <option value="Anniverssaire">Anniverssaire</option>
                                                            <option value="Mariage">Mariage</option>
                                                            <option value="Achat">Achat</option>
                                                            <option value="Aide ou don">Aide ou don</option>
                                                            <option value="Contribution">Contribution</option>
                                                            <option value="Participation">Participation</option>
                                                            <option value="Recouvrement">Recouvrement</option>
                                                            <option value="Autre">Autre</option>
                                                        </select>
                                                    </div><br>

                                                    <div class="form-group">
                                                        <label for="mode">Préciser si votre PayLink est accessible à un
                                                            groupe restreint de personnes ou public.</label><br><br>
                                                        <select v-model="formData.mode" class="form-control" id="mode" required>
                                                            <option disabled value="">
                                                                Choisir un mode
                                                            </option>
                                                            <option value="public">Public</option>
                                                            <option value="privé">Privé</option>
                                                        </select>
                                                    </div> <br />
                                                    <div class="form-group">
                                                        <label for="description">Brève description de votre PayLink qui présente
                                                            vos attentes et objectifs visés auprès de vos
                                                            utilisateurs (au plus 150 caractères)</label><br><br>
                                                        <textarea v-model="formData.description" class="form-control" id="description" placeholder="Description"></textarea>
                                                    </div> <br />
                                                    <div class="form-group">
                                                        <label for="montant_min">
                                                            Indiquez le montant minimum à payer.</label><br><br>
                                                        <input v-model="formData.montant_min" type="number" class="form-control" id="montant_min" />
                                                    </div>
                                                    <button @click="nextStep" class="btn btn-primary mt-3">
                                                        Suivant
                                                    </button>
                                                </div>
                                                <div v-if="step === 2">
                                                    <div class="form-group">
                                                        <label for="expiration_at">
                                                            Indiquez jusqu'à quelle date ce lien de paiement
                                                            sera valide.</label><br><br>
                                                        <input v-model="formData.expiration_at" type="date" class="form-control" id="expiration_at" />
                                                    </div> <br />
                                                    <div class="form-group">
                                                        <label for="withdrawal_number">Indiquez le numéro de retrait associées à ce
                                                            paylink.</label><br><br>
                                                        <input v-model="formData.withdrawal_number" type="text" class="form-control" id="withdrawal_number" placeholder="Numéro de Retrait" required />
                                                    </div>
                                                    <button @click="prevStep" class="btn btn-secondary mt-3">
                                                        Précédent
                                                    </button>
                                                    <span>&nbsp;</span><span>&nbsp;</span><span>&nbsp;</span>
                                                    <button @click="nextStep" class="btn btn-primary mt-3">
                                                        Suivant
                                                    </button>
                                                </div>
                                                <div v-if="step === 3">
                                                    <div class="form-group">
                                                        <label for="image">Vous pouvez ajouter une image ou un logo avec
                                                            le lien de paiement.</label><br><br>
                                                        <input @change="handleImageUpload" type="file" class="form-control" id="image" />
                                                    </div> <br />
                                                    <button @click="prevStep" class="btn btn-secondary mt-3">
                                                        Précédent
                                                    </button>
                                                    <span>&nbsp;</span><span>&nbsp;</span><span>&nbsp;</span>
                                                    <button type="submit" class="btn btn-primary mt-3">
                                                        Soumettre
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    </div>
</div>
</template>

<style scoped>
</style>
