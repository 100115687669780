<template>
    <div>
      <Header />
      <div class="container-fluid">
        <div class="row">
          <SideBar />
  
          <main class="main-wrapper col-md-9 ms-sm-auto py-4 col-lg-10 px-md-4 border-start">
            <div class="title-group mb-3">
              <h1 class="h2 mb-0">Profil</h1>
            </div>
  
            <div class="row my-4">
              <div class="col-lg-12 col-12">
                <div class="custom-block custom-block-profile">
                  <div class="row">
                    <div class="col-lg-12 col-12 mb-3">
                      <h6>INFORMATION GENERALE</h6>
                    </div>
  
                    <div class="col-lg-3 col-12 mb-4 mb-lg-0">
                      <div class="custom-block-profile-image-wrap">
                        <!-- Utilisez la propriété 'profile' pour afficher l'image -->
                        <img src="/images/LOGOAFRICAMONEY.png"
                          class="custom-block-profile-image img-fluid" alt="africamoney" />
                        <a href="/client/parametre" class="bi-pencil-square custom-block-edit-icon"></a>
                      </div>
                    </div>
  
                    <div class="col-lg-9 col-12">
                      <p class="d-flex flex-wrap mb-2">
                        <strong>Nom :</strong>
                        <a>{{ userData.nom }}</a>
                      </p>
  
                      <p class="d-flex flex-wrap mb-2">
                        <strong>Adresse :</strong>
                        <a>{{ userData.adresse }}</a>
                      </p>
  
                      <p class="d-flex flex-wrap mb-2">
                        <strong>Télephone :</strong>
                        <a>{{ userData.telephone }}</a>
                      </p>
  
                      <p class="d-flex flex-wrap mb-2">
                        <strong>Régistre de commerce :</strong>
                        <a>{{ userData.numero_registre_commerce }}</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Header from "@/components/Header.vue";
  import SideBar from "@/components/SideBar.vue";
  
  export default {
    components: { Header, SideBar },
    data() {
      return {
        userData: {
          nom: '',
          adresse: '',
          telephone: '',
          numero_registre_commerce: ''
        }
      };
    },
    mounted() {
      // Récupération des données depuis sessionStorage
      const userDataString = sessionStorage.getItem('userData');
      if (userDataString) {
        try {
          const parsedUserData = JSON.parse(userDataString);
          this.userData.nom = parsedUserData.additional_info.nom;
          this.userData.adresse = parsedUserData.additional_info.adresse;
          this.userData.telephone = parsedUserData.additional_info.telephone;
          this.userData.numero_registre_commerce = parsedUserData.additional_info.numero_registre_commerce;
        } catch (error) {
          console.error('Erreur lors du parsing de userData:', error);
        }
      }
    }
  };
  </script>
  
  
  <style></style>
  