<template>
    <div>
    
        <Head />
        <br /><br /><br />
    
        <div class="col-lg-12 col-12 text-center">
            <div v-if="paylink" class="d-flex flex-column align-items-center justify-content-center">
                <!-- Afficher l'image et le bouton si le paylink n'est pas désactivé -->
                <div v-if="paylink.status !== 'desactived'" class="image-container mb-3">
                    <img :src="BACK_URL + paylink.image" alt="Logo du paylink" class="img-fluid paylink-image" />
                </div>
    
           
                <div v-else class="alert alert-warning mt-3">
                    Ce lien de paiement est désactivé et n'est plus disponible.
                    <img src="/images/unavailable_link.svg" alt="Logo du paylink" class="img-fluid paylink-image" />
                </div>
    
                <h6>{{ paylink.objet }}</h6>
                <p><strong>Description:</strong> {{ paylink.description }}</p>
    
                <br /> <br />
                <!-- Bouton "Payer" visible seulement si le paylink n'est pas désactivé -->
                <button v-if="paylink.status !== 'desactived'" class="btn btn-success mt-4" @click="showSupportModal" style="
                      background-color: #006666;
                      border-color: #006666;
                      font-size: 1.25rem;
                      padding: 10px 20px;
                    ">
                    Payer <i class="bi bi-credit-card me-2"></i>
                </button>
            </div>
        </div>
    
        <!-- Modal -->
        <div class="modal fade" id="supportModal" tabindex="-1" aria-labelledby="supportModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header" style="background-color: #006666">
                        <h5 class="modal-title" id="supportModalLabel" style="color: white">
                            NOUVEAU PAIEMENT
                        </h5>
                    </div>
                    <div class="modal-body">
                        <ul class="nav nav-tabs" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link" :class="{ active: selectedTab === 'MTN' }" @click.prevent="handleTabClick('MTN')" href="#">
                                    <img src="/images/logomtn.svg" alt="MTN" class="img-fluid" style="height: 50px; margin-right: 28px" />
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" :class="{ active: selectedTab === 'MOOV' }" @click.prevent="handleTabClick('MOOV')" href="#">
                                    <img src="/images/logomoov.png" alt="MOOV" class="img-fluid" style="height: 50px; margin-right: 28px" />
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" :class="{ active: selectedTab === 'CELTIIS' }" @click.prevent="handleTabClick('CELTIIS')" href="#">
                                    <img src="/images/celtislogo.png" alt="CELTIIS" class="img-fluid" style="height: 50px; margin-right: 28px" />
                                </a>
                            </li>
                        </ul>
                        <div v-if="isUnavailable" class="alert alert-info mt-3" role="alert">
                            Non disponible
                        </div>
                        <div v-else>
                            <!-- Formulaire pour entrer les détails de paiement -->
                            <div class="mb-3">
                                <label for="nom" class="form-label">Nom</label>
                                <input type="text" class="form-control" id="nom" v-model="nom" placeholder="Entrez votre nom" required />
                            </div>
                            <div class="mb-3">
                                <label for="prenom" class="form-label">Prénom</label>
                                <input type="text" class="form-control" id="prenom" v-model="prenom" placeholder="Entrez votre prénom" required />
                            </div>
                            <div class="mb-3">
                                <label for="amount" class="form-label">Montant</label>
                                <input type="number" class="form-control" id="amount" v-model="amount" min="1" />
                            </div>
                            <div class="mb-3">
                                <label for="paymentNumber" class="form-label">Numéro de paiement</label>
                                <input type="text" v-model="paymentNumber" @blur="validateAndPrefixNumber" class="form-control" id="paymentNumber" placeholder="Ex:00000000" required />
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" @click="processPayment" style="background-color: #006666; border-color: #006666">
                            Envoyer
                        </button>
                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
                            Quitter
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </template>
    

  
  
<script>
import axios from "@/axs/axios.js";
import Head from "@/components/Head.vue";
import Swal from "sweetalert2";
import {
    ref,
    onMounted
} from "vue";
import {
    useRoute
} from "vue-router";
import {
    BACK_URL
} from "@/entreprise_logo.js";
export default {
    components: {
        Head,
    },
    setup() {
        const route = useRoute();
        const entreprise = ref(null);
        const amount = ref(1);
        const paymentNumber = ref("");
        const currency = ref("XOF");
        const externalId = ref("12345678");
        const payer = ref({
            partyIdType: "MSISDN",
            partyId: "",
        });

        const selectedTab = ref("MTN");
        const nom = ref("");
        const prenom = ref("");
        const clientId = ref(null);
        const paylinkId = ref(null);
        const isCheckingStatus = ref(false);
        const isProcessingPayment = ref(false);
        const paymentReferenceId = ref(null);
        const isUnavailable = ref(false);

        const validateAndPrefixNumber = () => {
            if (paymentNumber.value && !paymentNumber.value.startsWith("229")) {
                paymentNumber.value = `229${paymentNumber.value}`;
            }
        };

        const showSupportModal = () => {
            const supportModal = new bootstrap.Modal(
                document.getElementById("supportModal")
            );
            supportModal.show();
        };

        const handleTabClick = (tab) => {
            if (tab === "MOOV" || tab === "CELTIIS") {
                isUnavailable.value = true;
            } else {
                isUnavailable.value = false;
            }
            selectedTab.value = tab;
        };

        const processPayment = async () => {
            if (amount.value <= 0 || !paymentNumber.value) {
                Swal.fire({
                    icon: "warning",
                    title: "Champs invalides",
                    text: "Veuillez entrer un montant positif et un numéro de paiement.",
                });
                return;
            }

            payer.value.partyId = paymentNumber.value;

            Swal.fire({
                title: "Paiement en cours",
                text: "Votre paiement est en cours de traitement, veuillez patienter...",
                icon: "info",
                didOpen: () => {
                    Swal.showLoading(); // Affiche l'indicateur de chargement
                },
            });

            try {
                const data = {
                    amount: amount.value,
                    paymentNumber: paymentNumber.value,
                    currency: currency.value,
                    externalId: externalId.value,
                    payer: payer.value,
                    nomdestinataire: `${nom.value} ${prenom.value}`,
                    client_id: clientId.value,
                    paylink_id: paylinkId.value,
                    mode: 1,
                    numero: paymentNumber.value,
                    operateur: "MTN",
                };

                console.log(data);
                const response = await axios.post("/process-payment-paylinks", data);

                if (response.status === 200 || response.status === 202) {
                    paymentReferenceId.value = response.data.paiement.referenceId;
                    console.log(response.data.paiement.referenceId);
                    startCheckingStatus(paymentReferenceId.value);

                    Swal.close();

                    // Modal personnalisé avec SweetAlert
                    Swal.fire({
                        html: `
                      <div class="modal-content" style="background-color: #f0f0f0;">
                          <div class="modal-body text-center">
                            <!-- Indicateur de chargement -->
                            <div class="spinner-border text-primary" role="status">
                              <span class="visually-hidden">Chargement...</span>
                            </div>
                            <!-- Message de traitement -->
                            <p class="mt-3">Traitement du paiement en cours. Veuillez patienter...</p>
                            <!-- Affichage du montant -->
                            <h2 class="mt-4">Total à payer</h2>
                            <div class="amount">${amount.value} XOF *</div>
                            <!-- Icône visuelle -->
                            <div class="text-center mb-4">
                              <span class="hand-icon" style="font-size: 2rem;">✋📱</span>
                            </div>
                            <!-- Instructions pour l'utilisateur -->
                            <p class="mt-3"><strong>Veuillez consulter votre téléphone</strong></p>
                            <p>Entrez votre code de confirmation Mobile Money pour valider le paiement.</p>
                            <!-- Instructions alternatives -->
                            <div class="mt-4">
                              <p><strong>Vous n'avez pas reçu de demande de paiement ?</strong></p>
                              <p class="highlight-text">Tapez *880# option 8, option 2 puis 1</p>
                            </div>
                          </div>
                      </div>
                  `,
                        showConfirmButton: false, // Supprime le bouton OK
                        allowOutsideClick: false, // Empêche de fermer le modal en cliquant à l'extérieur
                    });
                }
            } catch (error) {
                Swal.fire({
                    icon: "error",
                    title: "Erreur lors du paiement",
                    text: "Une erreur est survenue. Veuillez réessayer.",
                });
                console.error("Erreur lors du paiement :", error);
            }
        };

        const startCheckingStatus = (referenceId) => {
            isCheckingStatus.value = true;
            const maxDuration = 60000;
            const interval = 1000;
            const startTime = Date.now();

            const checkStatus = async () => {
                if (!isCheckingStatus.value) return;

                try {
                    const response = await axios.get(
                        `/check-payment-status-paylinks/${referenceId}`
                    );
                    if (response.status === 200) {
                        const status = response.data.status;
                        if (status === "SUCCESSFUL") {
                            Swal.fire({
                                icon: "success",
                                title: "Paiement réussi",
                                confirmButtonColor: "#006666",
                            });

                            resetForm();
                            $("#addcard").modal("hide");
                            isProcessingPayment.value = false;
                            stopCheckingStatus();
                        } else if (status === "FAILED") {
                            Swal.fire({
                                icon: "error",
                                title: "Paiement échoué",
                                text: "Votre paiement a échoué. Veuillez réessayer.",
                                confirmButtonColor: "#006666",
                            });
                            isProcessingPayment.value = false;
                            stopCheckingStatus();
                        } else if (
                            status === "PENDING" &&
                            Date.now() - startTime < maxDuration
                        ) {
                            setTimeout(() => checkStatus(), interval);
                        } else {
                            Swal.fire({
                                icon: "warning",
                                title: "Délai d'attente dépassé",
                                text: "Le paiement est toujours en cours. Veuillez réessayer.",
                                confirmButtonColor: "#006666",
                            });
                            isProcessingPayment.value = false;
                            stopCheckingStatus();
                        }
                    }
                } catch (error) {
                    console.error(
                        "Erreur lors de la vérification du statut du paiement :",
                        error
                    );
                    if (Date.now() - startTime < maxDuration) {
                        setTimeout(() => checkStatus(), interval);
                    } else {
                        Swal.fire({
                            icon: "warning",
                            title: "Délai d'attente dépassé",
                            text: "Le paiement est toujours en cours. Veuillez réessayer.",
                            confirmButtonColor: "#006666",
                        });
                        isProcessingPayment.value = false;
                        stopCheckingStatus();
                    }
                }
            };

            checkStatus();
        };

        const stopCheckingStatus = () => {
            isCheckingStatus.value = false;
        };

        const resetForm = () => {
            // Réinitialiser les champs du formulaire si nécessaire
            amount.value = 1;
            paymentNumber.value = "";
            currency.value = "XOF";
            externalId.value = "12345678";
            payer.value.partyId = "";
            nom.value = "";
            prenom.value = "";
            clientId.value = null;
        };

        onMounted(() => {
            // Appeler fetchPaylinkDetails pour obtenir client_id
            const fetchPaylinkDetails = async () => {
                try {
                    const id = route.params.id;
                    paylinkId.value = id;
                    const response = await axios.get(`/show-paylinks/${id}`);
                    entreprise.value = response.data;
                    clientId.value = entreprise.value.client_id;
                } catch (error) {
                    console.error(
                        "Erreur lors de la récupération des détails du paylink :",
                        error
                    );
                }
            };

            fetchPaylinkDetails();
        });

        return {
            entreprise,
            amount,
            paymentNumber,
            currency,
            externalId,
            payer,
            selectedTab,
            nom,
            prenom,
            clientId,
            paylinkId,
            showSupportModal,
            processPayment,
            validateAndPrefixNumber,
            startCheckingStatus,
            stopCheckingStatus,
            BACK_URL: BACK_URL,
            resetForm,
            handleTabClick,
            isUnavailable,
        };
    },
    data() {
        return {
            paylink: null,
            loading: true,
            error: null,
            client_id: null,
        };
    },
    mounted() {
        this.fetchPaylinkDetails();
    },
    methods: {
        async fetchPaylinkDetails() {
            try {
                const paylinkId = this.$route.params.id;
                const response = await axios.get(`/show-paylinks/${paylinkId}`);
                this.paylink = response.data;
                // Stocker le client_id à partir de la réponse
                this.client_id = this.paylink.client_id;
            } catch (error) {
                this.error = "Erreur lors de la récupération des détails du paylink";
                console.error(error);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
  
  
<style scoped>
.image-container {
    max-width: 100%;
    overflow: hidden;
}

.image-container img {
    width: 100%;
    height: auto;
}

.paylink-image {
    max-width: 300px;
    width: 100%;
    height: auto;
}

@media (min-width: 768px) {
    .paylink-image {
        max-width: 400px;
    }
}

@media (min-width: 1200px) {
    .paylink-image {
        max-width: 500px;
    }
}
</style>
